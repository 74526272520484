import React, { useState } from "react";
import Container from "react-bootstrap/Container";
// import { Link } from "react-router-dom";
import { Link } from 'react-router-dom';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from 'react-bootstrap/Button';
// import { Link as Newlink } from "react-scroll";
import useContentful from "../hooks/use-contentful.js";
import Preloader from './Preloader';
import styles from "../styles/Navigation.module.scss";
import logo from './../assets/logo-transparent.png';
import { HashLink } from 'react-router-hash-link';

const Navi = (props) => {
  const [isActive, setActive] = useState(null);

  const query = `
    query{
      navigationBar(id:"6Z4coDv7nJFonxuDvXuZaB",locale: "${props.locale}"){
        aboutUs
        aboutIntroduction
        aboutAdvisoryBoard
        aboutExco
        aboutMilestones
        ourRoots
        rootsOrigin
        rootsPreface
        rootsGenealogy
        events
        contactUs
      }
    }
  `
  let { data, errors } = useContentful(query);

  if (errors) 
    return (
      <span>
        {errors.map(error => error.message).join(',')}
      </span>
    );

  if (!data) 
    return <Preloader />;

  return (
    <>
      <div className={styles.navbar}>
        <Container fluid>
          <Navbar expand="lg">
          <Navbar.Brand>
            <Link className="text-decoration-none text-white" to="/">
            <img
              src={logo}
              height="70"
              className="d-inline-block align-top"
              alt="Fooclan logo"
            />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle>
            <div onClick = {()=>setActive(!isActive)} className={`${isActive ? 'hamburger-menu hamburger-menu-active' : 'hamburger-menu'}`}>
              <div className='hamburger-menu-wrapper'>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <NavDropdown title={data.navigationBar.aboutUs} id="basic-nav-dropdown" className={styles['nav-item']}>
                <NavDropdown.Item><HashLink to="/#introduction">{data.navigationBar.aboutIntroduction}</HashLink></NavDropdown.Item>
                <NavDropdown.Item><HashLink to="/#advisory">{data.navigationBar.aboutAdvisoryBoard}</HashLink></NavDropdown.Item>
                <NavDropdown.Item><HashLink to="/#exco">{data.navigationBar.aboutExco}</HashLink></NavDropdown.Item>
                <NavDropdown.Item><HashLink to="/#milestones">{data.navigationBar.aboutMilestones}</HashLink></NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={data.navigationBar.ourRoots} id="basic-nav-dropdown" className={styles['nav-item']}>
                <NavDropdown.Item><HashLink to="/our-roots#origin">{data.navigationBar.rootsOrigin}</HashLink></NavDropdown.Item>
                <NavDropdown.Item><HashLink to="/our-roots#preface">{data.navigationBar.rootsPreface}</HashLink></NavDropdown.Item>
                <NavDropdown.Item><HashLink to="/our-roots#genealogy">{data.navigationBar.rootsGenealogy}</HashLink></NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/events">{data.navigationBar.events}</Nav.Link>
            </Nav>
            {/* <div>
              <Locale locale={locale} />
            </div> */}
            <div>
              <Nav.Link as={Link} to="/contact"><Button variant="primary">{data.navigationBar.contactUs}</Button></Nav.Link>
            </div>
          </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    </>
  )
};

export default Navi;
