import React from "react";
import {
  Container,
  Image,
} from "react-bootstrap";
import useContentful from "../hooks/use-contentful.js";
import Preloader from './Preloader';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styles from "../styles/Roots.module.scss";
import { FilePdfFill } from 'react-bootstrap-icons';

function renderOptions(links) {
  const assetMap = new Map();
  for (const asset of links.assets.block) {
    assetMap.set(asset.sys.id, asset);
  }

  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        const asset = assetMap.get(node.data.target.sys.id);
        return (
          <a href={asset.url} target="_blank" rel="noreferrer"><FilePdfFill size={20} style={{ paddingRight: "5px" }} />{asset.fileName}</a>
        );
      },
    },
  };
}


const Roots = (props) => {
  const query = `
    query{
      roots(id:"5kPVWn1fbyYr9XaCQFxeQX",locale: "${props.locale}"){
        pageTitle
        section1Title
        originOfFuClan {
          json
          links{
            assets {
              block {
                sys {
                  id
                }
                url
                fileName
              }
            }
          }
        }
        banner{
          url
        }
        section2Title
        prefaceOfOriginOfFuClan{
          json
          links{
            assets {
              block {
                sys {
                  id
                }
                url
                fileName
              }
            }
          }
        }
        section3Title
        genealogyChart{
          url
        }
      } 
    }
  `

  let { data, errors } = useContentful(query);

  if (errors) 
    return (
      <span>
        {errors.map(error => error.message).join(',')}
      </span>
    );

  if (!data) 
    return <Preloader />;

  return (
    <>
      <div className={styles['our-roots']} id="roots">
        <Container fluid>
          <div className="my-5" id="origin">
            <div className={styles['title']}>{data.roots.pageTitle}</div>
            <div className={styles['heading']}>{data.roots.section1Title}</div>
            <div className={styles['desc']}>
              {documentToReactComponents(data.roots.originOfFuClan.json, renderOptions(data.roots.originOfFuClan.links))}
            </div>
          </div>
        </Container>
        <Image src={data.roots.banner.url} fluid className="w-100" />
        <Container fluid>
          <div className="my-5" id="preface">
            <div className={styles['heading']}>{data.roots.section2Title}</div>
            <div className={styles['desc']}>
              {documentToReactComponents(data.roots.prefaceOfOriginOfFuClan.json, renderOptions(data.roots.prefaceOfOriginOfFuClan.links))}
            </div>
          </div>
          <div className="my-5 text-center" id="genealogy">
            <div className={styles['heading']}>{data.roots.section3Title}</div>
            <Image src={data.roots.genealogyChart.url} fluid />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Roots;
