import React from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button
} from "react-bootstrap";
import useContentful from "../hooks/use-contentful.js";
import Preloader from './Preloader';
import styles from "../styles/Home.module.scss";
import heroImg from "../assets/home-hero.jpeg";
import { ArrowDown, ArrowRight } from 'react-bootstrap-icons';
import EventBanner from "../components/EventBanner";

const Home = (props) => {
  const query = `
    query{
      contactUs(id:"n8PWJ8MVibS4quygDTCqc"){
        membershipFormEng{
          url
        }
      }
      introduction(id: "3cskcSRpaHAvTvWXNlTcUM",locale: "${props.locale}"){
        welcomeTitle
        subtitle
        sectionTitle
        bigDescription
        textRight
        textLeft
        imageLeft {
          title
          url
        }
        imageRight {
          title
          url
        }
        section1
        advisory{
          title
          url
        }
        section2
        exco{
          title
          url
        }
        section3
        ourMilestones
      }
      advisoryBoardItemCollection{
        items{
          chineseName
          englishName
          additionalField
          englishRole
          chineseRole
          boardMemberImage{
            title
            url
          }
        }
      }
    }
    `
  let { data, errors } = useContentful(query);

  if (errors) 
    return (
      <span>
        {errors.map(error => error.message).join(',')}
      </span>
    );
  if (!data) 
    return <Preloader />;

  return (
    <>
      <div className={styles.home} id="home">
        <EventBanner />
        <Container fluid>
          <div className={styles['home-hero']}>
            <Image src={heroImg} fluid />
            <div className={styles['text-wrapper']}>
              <div className={styles['heading']}>{data.introduction.welcomeTitle}</div>
              <div className={styles['desc']}>{data.introduction.subtitle}</div>
              <Button variant="primary" href={data.contactUs.membershipFormEng.url} target="_blank">{props.locale==='zh-Hans-SG'?'​加入我们':'Register for Membership'}</Button>
            </div>
            <div className={styles.arrow}>
              <ArrowDown size={50} color={'#8D2424'} />
            </div>
          </div>
          <div className={styles['home-about']} id="introduction">
            <div className={styles['heading']}>{data.introduction.sectionTitle} /</div>
            <div className={styles['desc']}>{data.introduction.bigDescription}</div>
          </div>
          <div className={styles['home-about-us']}>
            <Row className="d-flex justify-content-between pb-5">
              <Col lg={1}></Col>
              <Col lg={4}>
                <Image src={data.introduction.imageLeft.url} fluid className="w-100" />
              </Col>
              <Col lg={6}>
                <div className="mb-4">
                  {data.introduction.textRight}
                </div>
              </Col>
            </Row>
            <Row className={styles['desc']}>
              <Col lg={6} className="order-lg-last">
                <Image src={data.introduction.imageRight.url} fluid />
              </Col>
              <Col lg={6}>
                <div className="mb-4">
                  {data.introduction.textLeft}
                </div>
              </Col>
            </Row>
          </div>
          <div className={styles['home-advisory-board']} id="advisory">
            <div className={styles['title']}>
              {data.introduction.section1}
            </div>
            <div>
              <Row className="justify-content-md-center">
                {
                 data.advisoryBoardItemCollection.items.slice().reverse().map((item, i) => (
                  i<2 &&
                  <Col lg={3} className="text-center mb-4" key={i}>
                    <Image
                      className="mb-2"
                      src={item.boardMemberImage.url}
                      alt={item.boardMemberImage.title}
                      height="324"
                    />
                    <div className="text-primary">{item.chineseName}</div>
                    {item.additionalField && 
                    <div className="text-primary">{item.additionalField}</div>}
                    <div className="text-primary">{item.englishName}</div>
                    <div>{item.chineseRole}</div>
                    <div>{item.englishRole}</div>
                  </Col>
                 ))
                }
              </Row>
              <Row className="justify-content-md-center">
                {
                 data.advisoryBoardItemCollection.items.slice().reverse().map((item, i) => (
                  i>1 && i<4 &&
                  <Col lg={3} className="text-center mb-4" key={i}>
                    <Image
                      className="mb-2"
                      src={item.boardMemberImage.url}
                      alt={item.boardMemberImage.title}
                      height="324"
                    />
                    <div className="text-primary">{item.chineseName}</div>
                    {item.additionalField && 
                    <div className="text-primary">{item.additionalField}</div>}
                    <div className="text-primary">{item.englishName}</div>
                    <div>{item.chineseRole}</div>
                    <div>{item.englishRole}</div>
                  </Col>
                 ))
                }
              </Row>
              <Row>
                {
                 data.advisoryBoardItemCollection.items.slice().reverse().map((item, i) => (
                  i>3 &&
                  <Col lg={3} className="text-center mb-4" key={i}>
                    <Image
                      className="mb-2"
                      src={item.boardMemberImage.url}
                      alt={item.boardMemberImage.title}
                      height="324"
                    />
                    <div className="text-primary">{item.chineseName}</div>
                    {item.additionalField && 
                    <div className="text-primary">{item.additionalField}</div>}
                    <div className="text-primary">{item.englishName}</div>
                    <div>{item.chineseRole}</div>
                    <div>{item.englishRole}</div>
                  </Col>
                 ))
                }
              </Row>
            </div>
          </div>
          <div className={styles['home-exco']} id="exco">
            <div className={styles['title']}>
              {data.introduction.section2}
            </div>
            <div>
              <Image src={data.introduction.exco.url} fluid />
            </div>
          </div>
          <div className={styles['home-milestones']} id="milestones">
            <div className={styles['title']}>
              {data.introduction.section3}
            </div>
            <div className={styles['timeline']}>
              {data.introduction.ourMilestones.map((item, i) => (
                i%2===0 ? 
                <div className={styles['timeline-item']} key={i}>
                  <span className={styles['timeline-line']}></span>
                  <span className={styles['timeline-dot']}></span>
                  <Row className="d-flex align-items-center">
                    <Col lg={6} className={styles['year-right']}>
                      <div className={styles['year']}>{item.year}</div>
                    </Col>
                    <Col lg={6}>
                      <div className={styles['desc']}>{item.desc}</div>
                    </Col>
                  </Row>
                </div>
                : <div className={styles['timeline-item']} key={i}>
                  <span className={styles['timeline-line']}></span>
                  <span className={styles['timeline-dot']}></span>
                  <Row className="d-flex align-items-center">
                    <Col lg={6} className="order-lg-last">
                      <div className={styles['year']}>{item.year}</div>
                    </Col>
                    <Col lg={6}>
                      <div className={styles['desc']}>{item.desc}</div>                    
                    </Col>
                    {/* <Col lg={6}>
                      <div className={styles['year']}>{item.year}</div>
                    </Col> */}
                  </Row>
                </div>
                ))}
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Home;
