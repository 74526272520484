import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from 'react-bootstrap-icons';

const EventBanner = () => {
  return (
    <>
      <Link to="/world-fu-convention23" className="text-primary">
        <div className="text-center py-2 border-bottom event-banner">
          Click here to visit the Fu World Convention 2023 Website <ArrowRight size={20} color={'#8D2424'} />  
        </div>
      </Link>
      
    </>
  )
}
      
export default EventBanner;
      