import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Accordion
} from "react-bootstrap";
import useContentful from "../hooks/use-contentful.js";
import Preloader from './Preloader';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styles from "../styles/Contact.module.scss";
import { CloudDownload } from 'react-bootstrap-icons';

const Contact = (props) => {
  const query = `
    query{
      contactUs(id:"n8PWJ8MVibS4quygDTCqc",locale: "${props.locale}"){
        pageTitle
        title
        addressField
        address{
          json
        }
        telField
        tel
        faxField
        fax
        emailField
        email
        websiteField
        website
        title2
        membershipFormField1
        membershipFormEng{
          url
        }
        membershipFormChField
        membershipFormCh{
          url
        }
      }
      malaysiaCollection{
        items{
          clanSectionName{
            json
          }
          address{
            json
          }
          tel
          fax
          email
          website
        }
      }
      thailandCollection{
        items{
          clanSectionName{
            json
          }
          address{
            json
          }
          tel
          fax
          email
          website
        }
      }
      cambodiaCollection{
        items{
          clanSectionName{
            json
          }
          address{
            json
          }
          tel
          fax
          email
          website
        }
      }
      taiwanCollection{
        items{
          clanSectionName{
            json
          }
          address{
            json
          }
          tel
          fax
          email
          website
        }
      }
      hongKongCollection{
        items{
          clanSectionName{
            json
          }
          address{
            json
          }
          tel
          fax
          email
          website
        }
      }
      chinaCollection{
        items{
          clanSectionName{
            json
          }
          address{
            json
          }
          tel
          fax
          email
          website
        }
      }
    }
  `

  let { data, errors } = useContentful(query);

  if (errors) 
    return (
      <span>
        {errors.map(error => error.message).join(',')}
      </span>
    );

  if (!data) 
    return <Preloader />;

  return (
    <>
      <Container fluid>
        <div className={styles['contact']} id="contact">
          <div className="my-5">
            <div className={`text-center ${styles['title']}`}>{data.contactUs.pageTitle}</div>
            <div className="mb-5">
              <div className={styles['heading']}>{data.contactUs.title}</div>
              <div>{documentToReactComponents(data.contactUs.address.json)}</div>
              <div>{data.contactUs.telField}: {data.contactUs.tel}</div>
              <div>{data.contactUs.faxField}: {data.contactUs.fax}</div>
              <div>{data.contactUs.emailField}: {data.contactUs.email}</div>
              <div>{data.contactUs.websiteField}: {data.contactUs.website}</div>
            </div>
            <div className={styles['heading']}>{data.contactUs.title2}</div>
            <div className={`d-flex justify-content-between p-3 ${styles['form-section']}`}>
              <div>
                <div className={styles['form']}>{data.contactUs.membershipFormField1}</div>
              </div>
              <Button variant="primary" href={data.contactUs.membershipFormEng.url} target="_blank"><CloudDownload size={20} color={'#fff'} style={{ paddingRight: "5px" }} />{props.locale==='zh-Hans-SG'?'下载':'Download Here'}</Button>
            </div>
            <div class={`d-flex justify-content-between p-3 ${styles['form-section']}`}>
              <div>
                <div className={styles['form']}>{data.contactUs.membershipFormChField}</div>
              </div>
              <Button variant="primary" href={data.contactUs.membershipFormCh.url} target="_blank"><CloudDownload size={20} color={'#fff'} style={{ paddingRight: "5px" }} />{props.locale==='zh-Hans-SG'?'下载':'Download Here'}</Button>
            </div>
          </div>
          <div className="my-5">
            <div className={`mb-4 ${styles['heading']}`}>{props.locale==='zh-Hans-SG'?'世界各地':'Worldwide'}</div>
            <div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{props.locale==='zh-Hans-SG'?'柬埔寨':'Cambodia'}</Accordion.Header>
                  <Row>
                  {data.cambodiaCollection.items.reverse().map((item, i) => (
                    <Col lg={6} key={i}>
                      <Accordion.Body>
                        <div className="text-primary">{documentToReactComponents(item.clanSectionName.json)}</div>
                        <div>{documentToReactComponents(item.address.json)}</div>
                        {item.tel && <div>Tel: {item.tel}</div>}
                        {item.fax && <div>Fax: {item.fax}</div>}
                        {item.email && <div>Email: {item.email}</div>}
                        {item.website && <div>Website: {item.website}</div>}
                      </Accordion.Body>
                    </Col>
                  ))}
                  </Row>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>{props.locale==='zh-Hans-SG'?'中国': 'China'}</Accordion.Header>
                  <Row>
                  {data.chinaCollection.items.reverse().map((item, i) => (
                    <Col lg={6} key={i}>
                      <Accordion.Body>
                        <div className="text-primary">{documentToReactComponents(item.clanSectionName.json)}</div>
                        <div>{documentToReactComponents(item.address.json)}</div>
                        {item.tel && <div>Tel: {item.tel}</div>}
                        {item.fax && <div>Fax: {item.fax}</div>}
                        {item.email && <div>Email: {item.email}</div>}
                        {item.website && <div>Website: {item.website}</div>}
                      </Accordion.Body>
                    </Col>
                  ))}
                  </Row>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>{props.locale==='zh-Hans-SG'?'香港': 'Hong Kong'}</Accordion.Header>
                  <Row>
                  {data.hongKongCollection.items.reverse().map((item, i) => (
                    <Col lg={6} key={i}>
                      <Accordion.Body>
                        <div className="text-primary">{documentToReactComponents(item.clanSectionName.json)}</div>
                        <div>{documentToReactComponents(item.address.json)}</div>
                        {item.tel && <div>Tel: {item.tel}</div>}
                        {item.fax && <div>Fax: {item.fax}</div>}
                        {item.email && <div>Email: {item.email}</div>}
                        {item.website && <div>Website: {item.website}</div>}
                      </Accordion.Body>
                    </Col>
                  ))}
                  </Row>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>{props.locale==='zh-Hans-SG'?'马来西亚': 'Malaysia'}</Accordion.Header>
                  <Row>
                  {data.malaysiaCollection.items.reverse().map((item, i) => (
                    <Col lg={6} key={i}>
                      <Accordion.Body>
                        <div className="text-primary">{documentToReactComponents(item.clanSectionName.json)}</div>
                        <div>{documentToReactComponents(item.address.json)}</div>
                        {item.tel && <div>Tel: {item.tel}</div>}
                        {item.fax && <div>Fax: {item.fax}</div>}
                        {item.email && <div>Email: {item.email}</div>}
                        {item.website && <div>Website: {item.website}</div>}
                      </Accordion.Body>
                    </Col>
                  ))}
                  </Row>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>{props.locale==='zh-Hans-SG'?'台湾': 'Taiwan'}</Accordion.Header>
                  <Row>
                  {data.taiwanCollection.items.reverse().map((item, i) => (
                    <Col lg={6} key={i}>
                      <Accordion.Body>
                        <div className="text-primary">{documentToReactComponents(item.clanSectionName.json)}</div>
                        <div>{documentToReactComponents(item.address.json)}</div>
                        {item.tel && <div>Tel: {item.tel}</div>}
                        {item.fax && <div>Fax: {item.fax}</div>}
                        {item.email && <div>Email: {item.email}</div>}
                        {item.website && <div>Website: {item.website}</div>}
                      </Accordion.Body>
                    </Col>
                  ))}
                  </Row>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>{props.locale==='zh-Hans-SG'?'泰国': 'Thailand'}</Accordion.Header>
                  <Row>
                  {data.thailandCollection.items.reverse().map((item, i) => (
                    <Col lg={6} key={i}>
                      <Accordion.Body>
                        <div className="text-primary">{documentToReactComponents(item.clanSectionName.json)}</div>
                        <div>{documentToReactComponents(item.address.json)}</div>
                        {item.tel && <div>Tel: {item.tel}</div>}
                        {item.fax && <div>Fax: {item.fax}</div>}
                        {item.email && <div>Email: {item.email}</div>}
                        {item.website && <div>Website: {item.website}</div>}
                      </Accordion.Body>
                    </Col>
                  ))}
                  </Row>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Contact;