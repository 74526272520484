import React from "react";
import {
  Container,
  Image,
  Button
} from "react-bootstrap";
import page404 from "../assets/page404-transparent.png";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <>
    <div className="d-flex align-items-center my-5 py-5">
      <Container fluid>
        <div className="text-center">
          <Image src={page404} fluid />
          <h1>Oops!</h1>
          <div className="my-3">We can't find the page you are looking for.</div>
          <Button variant="primary" as={Link} to="/" className="my-3">Back to Homepage</Button>
        </div>
      </Container>
    </div>
    </>
  )
}

export default Error;
