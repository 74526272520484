import React, { useState } from "react";
// import Container from "react-bootstrap/Container";
import {
  Container,
  Row,
  Col
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import styles from "../styles/Footer.module.scss";
import logo from './../assets/logo.jpeg';
import iconMail from './../assets/envelope-solid.svg';
import iconFb from './../assets/facebook-f.svg';
import iconLocation from './../assets/location-dot-solid.svg';

const Footer = () => {
  return (
    <>
      <footer className={styles.footer}>
        <Container fluid className={styles['footer-inner']}>
          <div>
            <ul>
              <li className={styles['footer-inner-wrapper']}>
                <a href="mailto:foocas@singnet.com.sg">
                  <div className={styles['icon-wrapper']}>
                    <img src={iconMail} height="15" />
                  </div>
                </a>
              </li>
              <li className={styles['footer-inner-wrapper']}>
                <a href="https://www.facebook.com/foo.cas" target="_blank">
                  <div className={styles['icon-wrapper']}>
                    <img src={iconFb} height="15" />
                  </div>
                </a>
              </li>
              <li className={styles['footer-inner-wrapper']}>
                <a href="https://goo.gl/maps/Jj5KqKj4NDDbnoMr8/" target="_blank">
                  <div className={styles['icon-wrapper']}>
                    <img src={iconLocation} height="15" />
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <nav className={styles['footer-nav']}>
            <div className={styles['footer-nav-group']}>
              <a href="/#introduction" className={styles['footer-nav-item']}>About Us</a>
              <a href="/our-roots" className={styles['footer-nav-item']}>Our Roots</a>
              <a href="/events" className={styles['footer-nav-item']}>Events</a>
              <a href="/contact" className={styles['footer-nav-item']}>Contact Us</a>
            </div>
          </nav>
          <div className="text-center">
            Copyright © 2022 Foo Clan Association (Singapore) 符氏社 (新加坡)
          </div>
        </Container>
      </footer>
    </>
  )

}

export default Footer;