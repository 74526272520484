import React from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button
} from "react-bootstrap";
import useContentful from "../hooks/use-contentful.js";
import Preloader from "./Preloader";
import Form from "../components/Form";
import styles from "../styles/Event.module.scss";
import heroImg from "../assets/wfc-hero.jpeg";
import { CloudDownload } from 'react-bootstrap-icons';

const WorldFuCon23 = (props) => {
  const query = `
    query{
      worldFuConvention2023(id:"1wZ8cMYiDor9N05vThTtoZ",locale: "${props.locale}"){
        heading
        chineseHeading
        headingDescription
        directionsPdf{
          url
        }
        aboutTitle
        aboutDesc
        secondHeading
        programmeHighlights
      }
    }
  `
let { data, errors } = useContentful(query);

if (errors) 
  return (
    <span>
      {errors.map(error => error.message).join(',')}
    </span>
  );
if (!data) 
  return <Preloader />;

  return (
    <>
      <div className={styles.event}>
        <Container fluid>
          <div className={styles['event-hero']}>
            <Image src={heroImg} className="w-100" fluid />
            <div className={styles['text-wrapper']}>
              <div className={styles['heading']}>{data.worldFuConvention2023.heading}<br/>{data.worldFuConvention2023.chineseHeading}</div>
              <div className={styles['desc']}>{data.worldFuConvention2023.headingDescription}</div>
              <Button className="my-2" variant="primary" href={data.worldFuConvention2023.directionsPdf.url} target="_blank"><CloudDownload size={20} color={'#fff'} style={{ paddingRight: "5px" }} />Directions to RWCC</Button>
            </div>
          </div>
          <div className={styles['event-about']}>
            <div className={styles['title']}>{data.worldFuConvention2023.aboutTitle} /</div>
            <div className={styles['desc']}>{data.worldFuConvention2023.aboutDesc}</div>
          </div>
          <div className={styles['event-programme']}>
            <div className={styles['heading']}>
              {data.worldFuConvention2023.secondHeading}
            </div>
            <Row>
              {data.worldFuConvention2023.programmeHighlights.map((item, i) => (
                <Col md={6} key={i} className="d-flex">
                  <div className={styles['item-name']}>
                    <div>{item.eng}</div>
                    <div>{item.ch}</div>
                  </div>
                </Col>
            ))}
            </Row>
          </div>
          <div className={styles['event-form']}>
            <div className={styles['heading']}>Registration Form</div>
            <Form/>
          </div>
        </Container>
      </div>
    </>
  )
}

export default WorldFuCon23;