import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navi from "./pages/Navigation";
import Home from './pages/Home';
import Roots from './pages/Roots';
import Events from './pages/Events';
import Contact from './pages/Contact';
import Footer from './pages/Footer';
import Error from './pages/Error';
// import Construction from './pages/Construction';
import WorldFuCon23 from './pages/WorldFuCon23';
import Locale from './components/Locale';
import ScrollToTop from "react-scroll-to-top";
import './App.css';

function App() {
  let [currentLocale, setLocale] = useState('en-US');

  function locale(l) {
    setLocale(l);
  }

  return (
    <>
      <ScrollToTop />
      <Navi locale={currentLocale} />
      <Locale locale={locale} />
      <Routes>
        <Route path="/" element={<Home locale={currentLocale} />} />
        <Route exact path="our-roots" element={<Roots locale={currentLocale} />} />
        <Route exact path="events" element={<Events locale={currentLocale} />} />
        <Route exact path="contact" element={<Contact locale={currentLocale} />} />
        <Route exact path="world-fu-convention23" element={<WorldFuCon23 locale={currentLocale} />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </>
  )
}

export default App;
