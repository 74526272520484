import React from "react";
import {
  Container,
  Image,
  Button
} from "react-bootstrap";
import spinner from "../assets/preloader.gif";

const Preloader = () => {
  return (
    <>
    <div className="d-flex align-items-center" style={{minHeight: "100vh"}}>
      <Container fluid>
        <div className="text-center">
          <h1 className="text-primary mb-4">Loading...</h1>
          <Image src={spinner} fluid />
        </div>
      </Container>
    </div>
    </>
  )
}

export default Preloader;
