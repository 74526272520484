import React from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Carousel,
} from "react-bootstrap";
import Moment from "react-moment";
import useContentful from "../hooks/use-contentful.js";
import Preloader from './Preloader';
import styles from "../styles/Events.module.scss";

const Events = (props) => {
  const query = `
    query{
      events(id:"Odq5IAGLtMm6Wz7ecluPU",locale: "${props.locale}"){
        eventsAndActivities
        section1Title
        section2Title
      }
      eventCollection(locale: "${props.locale}"){
        items{
          eventName
          eventDate
          eventDesc
          past
          imageCarouselCollection{
            items{
              url
            }
          }
        }
      }
    }
  `
  let { data, errors } = useContentful(query);

  if (errors) 
    return (
      <span>
        {errors.map(error => error.message).join(',')}
      </span>
    );

  if (!data) 
    return <Preloader />;

  return (
    <>
      <div className={styles['events']} id="events">
        <Container fluid>
          <div className="my-5" id="upcoming">
            <div className={styles['title']}>{data.events.eventsAndActivities}</div>
            <div className="mb-4 pb-4">
              <div className={styles['heading']}>{data.events.section1Title}</div>
              {
              data.eventCollection.items.map((item, i) => (
                !item.past &&
                <Row className="align-items-center mb-4" key={i}>
                  <Col lg={6}>
                    <Carousel>
                      {
                        item.imageCarouselCollection.items.map((pic, i) => (
                          <Carousel.Item key={i}>
                            <Image
                              className="d-block w-100"
                              src={pic.url}
                              alt="Event slide"
                              height="350"
                            />
                        </Carousel.Item>
                        ))
                      }
                      </Carousel>
                  </Col>
                  <Col lg={6}>
                    <div className={styles['event']}>
                      <div className={styles['name']}>
                        {item.eventName}
                      </div>
                      <div className={styles['date']}>
                        <Moment format="DD MMM yyyy">
                          {item.eventDate}
                        </Moment>
                      </div>
                      <div className={styles['desc']}>
                        {item.eventDesc}
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}
            </div>
            {/* <div className="my-5" id="past">
              <div className={styles['heading']}>{data.events.section2Title}</div>
              {data.eventCollection.items.map((item, i) => (
                item.past &&
                <Row className="align-items-center mb-4">
                  <Col lg={6}>
                    <Image src={item.eventImage.url} fluid />
                  </Col>
                  <Col lg={6}>
                    <div className={styles['event']}>
                      <div className={styles['name']}>
                        {item.eventName}
                      </div>
                      <div className={styles['date']}>
                        {item.eventDate}
                      </div>
                      <div className={styles['desc']}>
                        {item.eventDesc}
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}
            </div> */}
          </div>
        </Container>
      </div>
    </>
  )
}

export default Events;
