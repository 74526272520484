import React from 'react';
import { Container, Form } from 'react-bootstrap';

const Locale = (props) => {
  return (
    <>
      <Container fluid>
        <div className="locale-container">
          <div className="locale-buttons">
            <Form.Check
              onClick={()=>{props.locale('en-US')}}
              inline
              label="EN"
              name="group1"
              type="radio"
              id={`inline-radio-1`}
              defaultChecked
            />
            <Form.Check
              onClick={()=>{props.locale('zh-Hans-SG')}}
              inline
              label="CH"
              name="group1"
              type="radio"
              id={`inline-radio-2`}
            />
          </div>
        </div>
      </Container>
    </>
  )
}

export default Locale;